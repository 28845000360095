@keyframes success {
    from{
        background-color: #2fb63f;
    }
    to{
        background-color: white;
    }
}

@keyframes checkbox-grow {
    from {
        scale: 0;
    }
    to {
        scale: 1;
    }
}

.checkbox {
    scale: 0;
    animation: checkbox-grow 500ms forwards;
    animation-delay: 450ms;
}