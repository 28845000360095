.banner-image-container{
        overflow: hidden;
        position: relative;
        display: flex;
        width: 97%;
        align-items: flex-start;
        padding: 10px;
        justify-content: center;
        background-color: rgba(233, 120, 120, 0.095);
        border-radius: 50px;
        flex-direction: column;
} 

.banner-image-container img{
    object-fit: contain;
}

.first-people-container {
    display: flex;
    align-items: flex-start;
    position: absolute;
    flex-direction: row;
    left:10%;
    margin-right: 40%;
    bottom: 2vh;
    z-index: 2;
    width: 10vw;
    
}

.second-people-container{
    flex-direction: space-evenly;
    display: flex;
    justify-content: center;
    align-content: center;
    position: absolute;
    flex-direction: row;
    left:10%;
    margin-left: 40%;
    bottom: 2vh;
    z-index: 2;
    width: 6vw;
}

.second-people-container :first-child{
    scale: 1.2;
}

.car-container {
    display: flex;
    position: absolute;
    align-items: flex-start;
    align-self: flex-end;
    bottom: 1.5vh;
    left: 8%;
    z-index: 3;
    width: 25vw;
}


.image-fit{
    position: relative;
    object-fit:contain;
    width:100%;
}