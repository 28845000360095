
@media (max-width:768px) {
    .slogan-container {
        max-height: 80px;
        max-width: 100%;
        font-family: 'Montserrat Alternates', sans-serif;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .membercard-container{
        width:100%;
        height: 60px;
        max-height: 60px;
    }
    .subtitle-container{
        width:fit-content;
        margin-bottom: -90px;
    }
}

@media (min-width:768px) {
    .section-2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .membercard-container{
        width: 18%;
        max-height: 80px;
        max-width: 240px;

    }
    .slogan-container{
        width: 75%;
        max-height: 80px;
    }
}


.banner-container {
    display: flex;
    overflow: hidden;
    background: #8E0E00;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #912424, #1b2138);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #912424, #381b1b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */    
    border-top-width: 20px;
    border-top-color: darkgrey;
    padding-bottom: 20px;
    flex-direction: column;
    border-top-style: solid;
    border-top-width: 0.15px;
    border-top-color: gray;
    border-bottom-style: solid;
    border-bottom-width: 0.25px;
    border-bottom-color: gray;
    padding-left: 7%;
    padding-right: 7%;
}

.title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
    background-color: #2fb63f;
    align-items: flex-start;
}

.subtitle-container {
    font-weight: 700;
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: clamp(12pt, 4vw, 20pt);
    margin-bottom: -30px;
    margin-right: clamp(8.0rem,5vw, 10rem)
}

.name-container {
    margin-bottom: 60px;
    font-family: 'Fredoka One', cursive;
    height: fit-content;
    margin-top:-1vh;
    margin-bottom:-8vh;
}

.name-container h1 {
    color: #fefe14;
    
}

.name-container h2 {
    font-family: 'Fredoka One', cursive;
    margin-bottom: -10px;
}

.title-container-heading {
    font-size: clamp(2rem, 9vw, 70pt);
    flex-direction: row;
    text-transform: uppercase;
    padding-bottom: 0;
}

.title-container-heading:first-child{
    color: #fefe14;
}

.title-container-heading:last-child{
    color: white;
}

.section-1 {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.section-2 {
    width: 100%;
}

.section-2 h3{
    font-size: clamp(11pt,2vw, 14pt);
}

.membercard-container {
    display: flex;
    flex-direction: column;
    background-color: #2fb63f;
    height:15%;
    min-height: 50px;
    max-height: 100px;
    margin-top: 20px;
    border-radius: 20px;
    align-items: center;
    align-content: center;
    justify-content: center;
    align-self: flex-end;
    font-family: 'Fredoka One', cursive;
}

.membercard-container h3{
    margin-top: 15px;
    margin-bottom: -15px;
    font-size: x-large;
}

.membercard-container:last-child {
    bottom: -20px;

}

.custom-button {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    height: clamp(70px, 10vw, 140px);
    width: clamp(70px,10vw,140px); 
    background-color: #31b440;
    border-radius: 75px;
    border-style: solid;
    border-width: 5px;
    border-color: #522323;
    z-index: 3;
    position: absolute;
    left: 75%;
    margin-top: -5vh;
    object-fit: contain;
}
.custom-button:hover {
    scale: 1.05;
}

.slogan-container{
    background-color: lightgray;
    border-radius: 20px;
    margin-top: 20px;
}

.slogan-container h3{
    margin-top: 15px;
    padding-left: 2%;
}

.button-banner-container{
    position: relative;
}