.missionlist-container {
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom-style: outset;
    border-bottom-color: #e5e5e5 ;
    border-bottom-width: 0.5px;
    font-family: 'Montserrat Alternates', sans-serif;
    margin-bottom: 25px;
    padding-bottom: 20px;
}

.missionlist-container:hover {
    background-color: rgba(240, 240, 240, 0.615);
}

.missionlist-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.missionlist-title b{
    padding-right: 30px;
    color: gray;
    font-size: larger;
}

.missionlist-title p{
    font-weight: bold;
    font-size: clamp(16pt,5vw,20pt);
}

.body-text {
    margin-right: 20px;
    padding-left: 40px;
    font-size: larger;
    width: 90%;
}