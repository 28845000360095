
.why-container {
   display: flex;
   position: relative;
}

@media (max-width:768px) {
    .why-container{
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .vision-container{
        width: 50%
    }
    .mission-container{
        width: 50%;
    }
}

.vision-container {
    background-color: #e5e5e5;
    padding-left: 8%;
    padding-right: 8%;
}

.vision-container h3{
    font-size: clamp(22pt,5vw,26pt);
}

.vision-container h4 {
    font-weight: 600;
    font-size: clamp(16pt,5vw,22pt);
}

.why-container h2 {
    font-weight: bold;
    font-size: clamp(28pt,6vw,40pt);
    font-family: 'Montserrat Alternates', sans-serif;
}

.why-container h3 {
    font-family: 'Montserrat Alternates', sans-serif;
    border-bottom-style: solid;
    padding-bottom: 50px;
    border-bottom-color: lightslategray;
    align-self: center;
}

.mission-container {
    background-color: white;
    padding-left: 8%;
    padding-right: 8%;
}
/* 
@media (max-width:768px) {
    .mission-container{
        padding-left: 4%;
        padding-right:4%;
    }
} */



