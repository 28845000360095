.circle {
    animation: pulse 2s infinite;
    background-color: forestgreen;
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

@keyframes offline {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(139, 39, 34, 0.7);
    }
    70%{
        transform: scale(1);
        box-shadow: 0 0 0 2px rgba(139, 39, 34, 0) ;
    }
    100%{
        transform: scale(1);
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(34, 139, 34, 0.7);
    }
    70%{
        transform: scale(1);
        box-shadow: 0 0 0 8px rgba(34, 139, 34, 0) ;
    }
    100%{
        transform: scale(0.9);
    }
}