.community-container{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-left: 8%;
    padding-right: 8%;
    font-family: 'Montserrat Alternates', sans-serif;
    padding-bottom: 20px;
}

.community-container h3{
    align-self: center;
    font-size: clamp(24pt,6vw,38pt);
    font-weight: 400;
}

.community-container p{
    font-size: clamp(20pt,3vw,25pt);
    align-self: center;
    color: darkgray;
}
.title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    font-weight: bold;
    font-size: 30pt;
    background-color: white;
}
.line-spacer {
    height: 1px;
    width: 30%;
    background-color: black;
}
.newsletter-form-wrapper {
    display: flex;
    justify-content: center;
    font-family: inherit;
}

.newsletter-form-wrapper input {
    font-size: large;
    padding: 8px 12px;
}

.newsletter-form-wrapper button{
    font-size: large;
}

@media (max-width: 768px) {
    .form-newsletter-wrapper {
        min-width: 85%;
        padding: auto;
        max-height: 10px;
        margin-bottom: 40px;
    }

    .newsletter-form-wrapper {
        display: flex;
        flex-direction: column;
    }

    .arrow-button {
        margin-top: 10px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        justify-content: center;
    }

    .newsletter-form-wrapper input{
        border-radius: 5px;
        border-width: 0.5px;
    }

    .newsletter-success-container{
        width: 10vw;
        height: 50px;
    }
}

.form-newsletter-wrapper {
    border-style: hidden;
    align-self: center;
    height: 2rem;
    max-width: 30rem;
    position: relative;
    justify-content: center;
}

.newsletter-success-container {
    background-color: #2fb63f;
    width: 50vw;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.arrow-button {
    display: flex;
    color: whitesmoke;
    background-color: #2fb63f;
    padding: 10px 14px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-style: hidden;
    transition: all .3s ease;
    font-weight: bold;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
    font-family: inherit;
}

.arrow-button > .arrow {
    width: 6px;
    height: 6px;
    border-right: 2px solid whitesmoke;
    border-bottom: 2px solid whitesmoke;
    position: relative;
    transform: rotate(-45deg);
    margin: 0 6px;
    transition: all .3s ease;
}

.arrow-button > .arrow::before {
    display: block;
    background-color: currentColor;
    width: 3px;
    transform-origin: bottom right;
    height: 2px;
    position: absolute;
    opacity: 0;
    bottom: calc(-2px / 2);
    transform: rotate(45deg);
    transition: all .3s ease;
    content: "";
    right: 0;
}

.arrow-button:hover > .arrow {
    transform: rotate(-45deg) translate(5px, 5px);
    border-color: white;
}

.arrow-button:hover > .arrow::before {
    opacity: 1;
    width: 10px;
}

.arrow-button:hover {
    background-color: #2fb63f;
    color: #fff;
}