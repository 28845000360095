.section-header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    border-style: hidden;
}

@media (max-width: 768px) {
    .line-spacer {
        height: 1px;
    }
}

@media (max-width: 480px) {
    .line-spacer {
        max-width: 3.5rem;
    }
}

@media (min-width: 768px) {
    .line-spacer {
        height: 1px;
    }

}

.white {
    color: white;
}

.section-header-container h2{
    display: inline;
    font-size: clamp(22pt,6vw,40pt);
}