@keyframes success {
    from{
        background-color: #2fb63f;
    }
    to{
        background-color: white;
    }
}

@keyframes checkbox-grow {
    from {
        scale: 0;
    }
    to {
        scale: 1;
    }
}

.contact-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: white;
    padding-left: 8%;
    padding-right: 8%;
}

.info-container h2{
    margin-bottom: -5px;
}

@media (max-width:1100px) {
    .contact-container{
        display: flex;
        flex-direction: column;
        justify-content:space-evenly
    }
    .social-container {
        margin-top: -50px;
        margin-bottom:30px;
    }
}

@media (min-width:768px) {
    .contactinfo-container{
        padding-right: 5%;
    }
}
.contactinfo-container{
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 4%;
}

.info-container {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Montserrat Alternates', sans-serif;
}

.info-container p{
    font-size: large;
}

@media (max-width:768px) {
    .info-container{
        max-width:100%;
        width:80%;
        padding-bottom: 30px;
    }
    .message-input{
        max-width: 100%;
    }
}

.user-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.message-input {
    border-radius: 8px;
    padding: 10px;
    height: 350px;
    max-width: 900px;
    border-style: hidden;
    background-color: #2fb63f35;
    margin-bottom: 25px;
    resize: vertical;
    font-size: large;
    font-family: 'Montserrat Alternates', sans-serif;
}

.message-input:focus{
    outline: none;
}

.error-input {
    border-color: red;
    border-style: solid;
    border-width: 1px;
}
.input-container{
    border-style:hidden;
}

.success-container{
    background-color: #2fb63f;
    width: 95%;
    max-height: inherit;
    max-width: inherit;
    height: 30rem;
    border-radius: 10px;
    margin-right: 5%;
    margin-bottom: 4%;
    z-index: 500;
    justify-content: center;
    align-items: center;
}

.fade-background {
    animation: forwards success 450ms;
}

.checkbox {
    color: #2fb63f;
    scale: 0;
    animation: checkbox-grow 500ms forwards;
    animation-delay: 450ms;
}

.user-container input{
    border-radius: 8px;
    padding: 10px;
    width: 40%;
    height: 30px;
    background-color: #2fb63f35;
    margin-bottom: 25px;
    font-size: large;
    font-family: 'Montserrat Alternates', sans-serif;
}

.user-container input:focus{
    outline: none;
}
.submit-button{
    background-color: #2fb63f;
    padding: 10px;
    height: 60px;
    border-radius: 8px;
    border-style: hidden;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: larger;
    color: white;
}

.submit-button:hover {
    background-color: #55c862;
}
.social-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;

}

.social-container :hover {
    color:#2fb63fcf;
    cursor: pointer;
}

.h2 {
    font-weight: bold;
    font-size: clamp(28pt,6vw,40pt);
    font-family: 'Montserrat Alternates', sans-serif;
    color: #2fb63f;
    padding-left: 8%;
}

.invalid-container{
    border-style: solid;
    border-color: red;
    color:red;
}
