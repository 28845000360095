@keyframes slide-left {
    from {
        opacity: 0;
        margin-left: 20%;
        overflow: hidden;
    }

    to {
        margin-left: 0;
        opacity: 1;
    }
}

@keyframes slide-right {
    from {
        margin-left: 0;
        overflow: hidden;
        opacity:1;
    }

    to {
        margin-left: 10%;
        opacity: 0;
    }
}

.header-container {
    display: flex;
    background: #912424;  /* fallback for old browsers */
    background: linear-gradient(to right, #912424, #381b1b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    min-height: 70px;
    flex-direction: row;
    padding-right: 8%;
    padding-left: 8%;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}
.header-content-container {
    display: flex;
    flex-direction: row;
    align-content: center;
}

.logo-container {
    display: flex;
    height: 40px;
    align-self: center;
}


@media (min-width:769px) {
    .navbar-container.visible{
        left: 75%;
    }
    .navbar-container {
        overflow: hidden;
        position: absolute;
        display: flex;
        align-self: flex-end;
        top: 85px;
        flex-direction: column;
        color: cornsilk;
        animation: slide-left 750ms ease-in-out forwards;
        z-index: 2;
    }

    .navbar-item:hover{
        background-color: #e95c5c35;
    }
}


.hidden{
   animation: slide-right 550ms ease-in;
   animation-fill-mode: forwards;
}

@media (max-width:768px) {
    .navbar-container{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-content: center;
        background: #912424;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #912424, #1b2138);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #912424, #381b1b);
        top:0;
        right:0;
        width: 100%;
        height:100%;
        z-index: 4;
        align-items: center;
        opacity: 5px;
    }
    .navbar-container.visible {
        animation:slide-left 550ms ease-in forwards;
    }
    .navbar-item {
        list-style: none;
        border-bottom-style: none;
        border-bottom-width: 0px;
        padding-bottom: 20px;
        width: 100%;
        align-self: center;
    }

    .navbar-item:hover{
        background-color: transparent;
    }
    .signIn-container{
        width: 90%;
    }

    .close-button{
        margin-top: 2vh;
        margin-bottom:6vh;
        align-self: flex-end;
        background-color: #91242430;
        border-radius: .8rem;
        border-style: hidden;
        margin-right: 7%;
        height: 3.5rem;
        width: 3.5rem;
        color: white;
        font-size: larger;
        font-weight: 700;
    }

    .close-button:active{
        scale: 0.9;
    }

}

.menu-spacer {
    background-color: gray;
    min-height: 40px;
    align-self: center;
    min-width: 0.5px;
    margin-left: 30px;
    margin-right: 30px;
}

.navbar-item.clicked {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: gray;
    max-width: 140px;
    min-height: 60px;
}

@media (min-width:768px) {
    .navbar-item {
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: gray;
        max-width: 140px;
        height: 60px;
    }
}

.underline:hover{
    opacity: 0;
}


.navbar-item:hover {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
}

@media (min-width:768px) {
    .navbar-item.animation {
        list-style: none;
        animation: slide-left 350ms;
    }
}

.navbar-item p{
    color: whitesmoke;
    align-self: center;
    font-size: medium;
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 600;

}
.navbar-item b{
    color: gray;
    font-size: small;
    padding-right: 18px;
    align-self: flex-start;
}

.menu-container {
    display: flex;
    background-color: transparent;
    border-radius: 60px;
    border-style: hidden;
    align-items: center;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-content: center;
}

.menu-clicked:active {
    display: flex;
    background-color: rgba(228, 228, 228, 0);
    border-radius: 60px;
    border-style: hidden;
    align-items: center;
    scale: 0.9;
    justify-content: center;
    align-content: center;
}

.menu-container:active {
    display: flex;
    background-color: rgba(228, 228, 228, 0.764);
    border-radius: 60px;
    border-style: hidden;
    align-items: center;
    justify-content: center;
    align-content: center;
    scale: 0.9;
}

.menu-clicked {
    display: flex;
    background-color: rgba(233, 120, 120, 0.16);
    border-radius: 60px;
    border-style: hidden;
    align-items: center;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-content: center;
}

.menu-container:hover {
    display: flex;
    background-color: rgba(233, 120, 120, 0.16);
    border-radius: 60px;
    border-style: hidden;
    align-items: center;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}

.signIn-container {
    text-decoration: none;
    height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    align-self: center;
    border-radius: 15px;
    border-style: hidden;
    font-size: medium;
    background-color: whitesmoke;
}
