.about-container {
    position:relative;
    display: flex;
    font-family: 'Montserrat Alternates', sans-serif;
    flex-direction: column;
}

.red-background{
    background: #912424;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #912424, #1b2138);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #912424, #381b1b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding-left: 8%;
    padding-right: 8%;
}

.white-background {
    padding-top: 20px;
    background: linear-gradient(to right, #912424, #381b1b);
    height: 100%;
    padding-left: 8%;
    padding-right: 8%;
}

.transition-background{
    background: -webkit-linear-gradient(to bottom, -webkit-linear-gradient( to right, #912424, #1b2138), white);
    background: linear-gradient(to bottom, linear-gradient( to right, #912424, #1b2138), white);
    height: 80px;
    width: 100%
}

.story-container{
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
}

.column-direction-reverse{
    flex-direction: column;
}

@media (max-width:768px) {
    .story-container{
        display: flex;
        flex-direction: column;
    }

    .column-direction-reverse{
        flex-direction: column-reverse;
    }

    .story-image-container img {
        background-color: #ffffff0a;
        width: 70vw;
        border-radius: 50px;
        overflow: hidden;
    }

}

@media (min-width:768px) {
    .story-image-container img {
        background-color: #ffffff0a;
        max-width: 500px;
        width: 50vw;
        border-radius: 50px;
        overflow: hidden;
    }
}

.story-message-container h3{
    margin-top: -10px;
    font-size: clamp(24pt,6vw,38pt);
}

.story-message-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.story-message-container p{
    font-size: clamp(20pt,3vw,25pt);
    max-width: 100%;
}

.story-image-container{
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-right: 2%;
    align-items: center;
    padding-top: 40px;
}

.facebook-icon-container{
    top: 0.95em;
    display: flex;
    position: absolute;
    align-self: flex-end;
    z-index: 9;
    height: 60px;
    overflow: hidden;
    width: 60px;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    right: 5%;
    color:white;
    background-color: #4267B2;
}

.facebook-icon-container a{
    color: white;
    
}

.facebook-icon-container:hover{
    scale: 1.2;
}

@media (max-width:768px) {
    .collab-container{
        height: 60vh;
        min-height: 320px;
        max-width: 320px;
        overflow: hidden;
    }

    .collab-container img{
        top:60px;
    }
}


.collab-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    align-content: center;
    align-items: center;
    background-color:#ff595911;
    overflow: hidden;
    height: 18rem;
    width: 18rem;;
    border-radius: 30vw;
    min-width: 320px;
    max-width: 420px;
    margin-right: 5%;
}


.collab-align-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 15rem;
    width: 15rem;
}

.connecter{
    font-size: 5rem;
    font-family: 'Montserrat Alternates', sans-serif;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 20px;
    animation: rotate 1000ms ease-in-out;
    transform: rotate(45deg);
    z-index: 5;
}

.gocabs-logo{
    width: 100%;
    height: 80px;
    object-fit: cover;
}

.ddnl-logo{
    width: 100%;
}

.connecter:hover{
    animation: rotate 1500ms ease-in-out;
}

@keyframes rotate {
    from {
        transform: rotate(45deg);
    }

    to {
        transform: rotate(135deg);
    }
}