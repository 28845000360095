.footer-container {
    background-color: #231111;
    color: whitesmoke;
    padding-left: 8%;
    padding-right: 8%;
    font-family: 'Montserrat Alternates', sans-serif;
    padding: 40px 8%;
}

@media (max-width: 768px) {
    .footer-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-content img {
        height: 40px;
        object-fit: contain;
    }

    .footer-content p {
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 768px) {
    .footer-content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .footer-content img{
        height: 50px;
    }
}
